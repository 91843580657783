import qs from 'querystring';

import { IntegrationDataDecodeError } from '../components/PackagePicker/controller/errors';
import { IntegrationData } from '../types/common';

export function integrationDataToAppSectionParams(integrationData: IntegrationData) {
  const { planIds, navigateToPageProps, navigateToSectionProps, biOptions, verticalStatusContent, ...rest } =
    integrationData;
  return qs.stringify({
    appSectionParams: JSON.stringify({
      ...rest,
      planIds: planIds?.join(','),
      biOptions: biOptions && btoa(JSON.stringify(biOptions)),
      navigateToSectionProps: navigateToSectionProps && btoa(JSON.stringify(navigateToSectionProps)),
      navigateToPageProps: navigateToPageProps && btoa(navigateToPageProps),
      verticalStatusContent: verticalStatusContent && btoa(JSON.stringify(verticalStatusContent)),
    }),
  });
}

export function extractIntegrationData(appSectionParams: Record<string, string>): IntegrationData {
  try {
    const { planIds, navigateToSectionProps, navigateToPageProps, biOptions, verticalStatusContent, ...rest } =
      appSectionParams;
    return {
      ...rest,
      navigateToPageProps: navigateToPageProps && atob(navigateToPageProps),
      navigateToSectionProps: navigateToSectionProps && JSON.parse(atob(navigateToSectionProps)),
      verticalStatusContent: verticalStatusContent && JSON.parse(atob(verticalStatusContent)),
      biOptions: biOptions ? JSON.parse(atob(biOptions)) : undefined,
      planIds: planIds ? planIds.split(',') : undefined,
    };
  } catch (e) {
    throw new IntegrationDataDecodeError(e.message);
  }
}
