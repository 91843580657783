import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';

import { AppProps, MessageCode } from '../../../types/common';
import { errorToMessage } from './errors';
import { ListController } from './ListController';
import { CheckoutController } from './CheckoutController';
import { StatusController } from './StatusController';
import { RestrictedController } from './RestrictedController';
import { Router } from './Router';
import { PricingPlansBi } from '../../../services/bi';

export class App {
  constructor(
    public setProps: (props: Partial<AppProps>) => void,
    protected list: ListController,
    protected checkout: CheckoutController,
    protected status: StatusController,
    protected restricted: RestrictedController,
    protected router: Router,
    protected flowAPI: ControllerFlowAPI,
    protected bi: PricingPlansBi,
  ) {}

  public async initialize() {
    this.setProps({
      hideToast: this.hideToast,
      showToast: this.showToast,
      closeUpgradeModal: this.closeUpgradeModal,
      navigateToHomePage: () => this.router.gotoHomePage(),
      biUpgradeReferralClick: (referralInfo: string) => this.bi.upgradeReferralClick(referralInfo),
    });

    this.router.whenInit({
      list: (integrationData) => this.list.initialize(integrationData),
      checkout: (checkoutData) => this.checkout.initialize(checkoutData),
      status: (statusData) => this.status.initialize(statusData),
      restricted: ({ pageId, integrationData }) => this.restricted.initialize(pageId, integrationData),
    });

    this.router.whenNavigate({
      list: ({ integrationData, message }) => {
        this.setProps({ message });
        return this.list.update(integrationData);
      },
      checkout: async ({ integrationData, plan, order }) => {
        this.setProps({ selectedPlan: plan });
        return this.checkout.update(plan, integrationData, order);
      },
      status: ({ statusData, plan }) => this.status.update(statusData, plan),
      restricted: ({ pageId, integrationData }) => this.restricted.update(pageId, integrationData),
    });

    try {
      await this.router.initialize();
    } catch (e) {
      this.flowAPI.reportError(e);
      await this.list.initialize({});
      this.setProps({ message: errorToMessage(e) });
    }
  }

  hideToast = () => this.setProps({ message: undefined });
  showToast = (message: MessageCode) => this.setProps({ message });
  closeUpgradeModal = () => this.setProps({ showUpgradeModal: false });
}
